import React, { useEffect, useCallback, useContext } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import StoreContext from '../context/StoreContext'

import styles from '../css/product-page.module.css'

import CloseSVG from '../icons/white-close.svg'

const Zoom = ({ active, setActive, images, imageAltText, slideIndex, setSlideIndex }) => {

    const { setScrollLock } = useContext(StoreContext);

    const handleScrollLock = useCallback((active) => {
        setScrollLock(active);
    }, [setScrollLock])

    useEffect(() => {
        handleScrollLock(active);
    },[active, handleScrollLock])

    useEffect(() => {
        const setViewport = () => {
            let vp = (window.innerHeight - 90) * 0.01;
            document.documentElement.style.setProperty('--vp', `${vp}px`);
        }
        setViewport();
        window.addEventListener('resize', setViewport);
        return () => {
            window.removeEventListener('resize', setViewport);
        }
    })

    return(
        <div className={`${styles.zoom} ${active === true && styles.zoomActive}`}>
            <div className="relative" style={{ height: '100vh' }}>
                <div className={styles.zoomSlideContainer}>
                    { images.map((image, index) => (
                    <button
                        key={index}
                        className={`${styles.zoomSlide} ${slideIndex === index ? styles.zoomSlideActive : styles.zoomSlideHidden}`}
                        onClick={() => setActive(false)}
                    >
                        <GatsbyImage
                            alt={imageAltText}
                            image={getImage(image.localFile)}
                            style={{
                            display: 'block',
                            maxWidth: '1000px'
                            }}
                        />
                    </button>
                    ))}
                </div>        
                <div
                    className={`${styles.zoomControls} flex items-center laptop:flex-col`}
                >
                    <div className="flex items-center laptop:flex-col">
                    <button
                        className={`
                            ml-4 
                            mr-2
                            tablet:bg-white
                            tablet:py-2 
                            tablet:px-6 
                            tablet:mx-6
                            laptop:mx-auto
                            laptop:mt-6
                            laptop:mb-12
                        `}
                        onClick={() => setActive(false)}
                    >
                        <span className="hidden tablet:block font-bold text-dkblue">Close</span>
                        <img
                            alt="close button"
                            src={CloseSVG}
                            width="40"
                            className="tablet:hidden"
                        />
                    </button>
                    { images.map((image, index) => (
                        <button
                            key={index}
                            className={`${slideIndex === index && styles.thumbnailActive} bg-white ml-1 laptop:ml-0 laptop:mb-4`}
                            onClick={() => setSlideIndex(index)}
                            style={{ width: '60px' }}
                        >
                            <GatsbyImage
                                alt={imageAltText}
                                image={getImage(image.localFile)}
                                style={{
                                    display: 'block'
                                }}
                            />
                        </button>
                    ))}
                    <div className="h-full w-2 laptop:h-4"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Zoom;