import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import ProductPageSEO from '../components/product-page-seo'
import ProductForm from '../components/product-form'
import Zoom from '../components/zoom'
import MobileShareButton from '../components/mobile-share-button'
import ShareWidget from '../components/share-widget'
import DesktopShareLinks from '../components/desktop-share-links'

import styles from '../css/product-page.module.css'

import ArrowBackSVG from '../icons/arrow-back.svg'

const ProductPage = ({ data, location }) => {

    const product = data.shopifyProduct

    const pageUrl = `https://shop.rarechicago.com/product/${product.handle}/`

    const metafields = product.metafields

    const socialImageMetaField = []

    for(let i=0; i<metafields.length; i++) {
        if(metafields[i].namespace === 'social_sharing_image') {
          socialImageMetaField.push(metafields[i]);
        }
    }

    const socialCardImage = socialImageMetaField.length > 0 ? socialImageMetaField[0].value : null

    const schema = {
      "@context": "https://schema.org/", 
      "@type": "Product", 
      "name": product.title,
      "image": product.images[0].originalSrc,
      "description": product.description,
      "sku": product.variants[0].sku,
      "offers": {
        "@type": "Offer",
        "url": pageUrl,
        "priceCurrency": "USD",
        "price": product.variants[0].price,
        "availability": product.availableForSale === true ? `https://schema.org/InStock` : `https://schema.org/OutOfStock`,
        "itemCondition": "https://schema.org/NewCondition"
      },
      "Brand": {
        "name": "Rare Threads"
      }
    }

    const [zoom, setZoom] = useState(false);
    const [slideIndex, setSlideIndex] = useState(0);
    const [zoomIndex, setZoomIndex] = useState(0);

    const handleOpenZoom = (index) => {
        setZoomIndex(index);
        setZoom(true);
    }


    return(
        <Layout>
            <ProductPageSEO 
                title={product.title}
                description={product.description}
                pathname={location.pathname}
                schema={schema}
                image={socialCardImage}
            />
            <div className="max-viewport clear-header mx-auto pt-4">
                <div className="page-padding flex justify-between items-center">
                    <Link
                        to="/products/"
                        className="flex items-center"
                        style={{ width: '182px' }}
                    >
                        <img
                            alt="Arrow back"
                            src={ArrowBackSVG}
                            className={styles.arrowBack}
                        />
                        <p className="ml-2">Back to products</p>
                    </Link>
                    <MobileShareButton
                        className="mb-6 laptop:hidden"
                    />
                    <DesktopShareLinks
                        productTitle={product.title}
                        pageUrl={pageUrl}
                    />
                </div>
                <h2 className="page-padding">{product.title}</h2>
                <div className="tablet:flex">
                    <div className={styles.pageColumnOne}>
                        <div className={styles.gallery}>
                            {product.images.map((image, index) =>(
                                <button
                                  className={`${styles.slide} ${index === slideIndex ? styles.slideActive : styles.slideHidden}`}
                                  key={index}
                                  onClick={() => handleOpenZoom(index)}
                                >
                                  <GatsbyImage
                                      alt={product.title}
                                      image={getImage(image.localFile)}
                                      loading={index === 0 ? `eager` : `lazy`}
                                      style={{
                                        display: 'block',
                                        borderRadius: '.5rem'
                                      }}
                                      imgStyle={{
                                        borderRadius: '.5rem'
                                      }}
                                  />
                                </button>
                              ))}
                          </div>
                          { product.images.length > 1 &&
                          <div className={`hidden tablet:grid grid-cols-5 gap-x-4 gap-y-4 justify-around`}>
                              { product.images.map((image, index) => (
                                  <button
                                      key={index}
                                      className={`${slideIndex === index && styles.thumbnailActive} bg-white`}
                                      onMouseOver={() => setSlideIndex(index)}
                                      onClick={() => setSlideIndex(index)}
                                      onFocus={() => setSlideIndex(index)}
                                  >
                                      <GatsbyImage
                                          alt={product.title}
                                          image={getImage(image.localFile)}
                                          style={{
                                            display: 'block',
                                          }}
                                      />
                                  </button>
                              ))}
                          </div> }
                      </div>
                      <div className={`${styles.pageColumnTwo} page-padding tablet:flex flex-wrap-reverse`}>
                          <div className="w-full">
                              <ProductForm product={product} />
                          </div>
                          <div className="w-full">
                              <div className="tablet:mb-6" dangerouslySetInnerHTML={{ __html: product.descriptionHtml }} />
                          </div>
                      </div>
                  </div>
              </div>
              <Zoom
                  active={zoom}
                  setActive={setZoom}
                  images={product.images}
                  imageAltText={product.title}
                  slideIndex={zoomIndex}
                  setSlideIndex={setZoomIndex}
              />
              <ShareWidget
                  pageUrl={pageUrl}
                  productTitle={product.title}
              />
        </Layout>   
    )
}

export default ProductPage;

export const query = graphql`
  query($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      availableForSale
      productType
      description
      descriptionHtml
      shopifyId
      options {
        id
        name
        values
      }
      variants {
        id
        title
        sku
        price
        availableForSale
        shopifyId
        selectedOptions {
          name
          value
        }
      }
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      images {
        originalSrc
        id
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1000)
          }
        }
      }
      metafields {
        id
        namespace
        value
      }
    }
  }
`