import React, { useContext } from 'react'
import StoreContext from '../context/StoreContext'

import ShareSVG from '../icons/share.svg'

const MobileShareButton = ({ className }) => {

    const { setShareWidget } = useContext(StoreContext);

    const handleOpenShareWidget = () => {
        setShareWidget(true);
    }

    return(
        <button 
            className={className}
            onClick={() => handleOpenShareWidget()}
        >
            <img
                alt="Share icon"
                src={ShareSVG}
                style={{
                width: '27px',
                height: '27px'
                }}
            />
        </button>
    )
}

export default MobileShareButton;