import React, { useContext } from 'react'
import StoreContext from '../context/StoreContext'

import styles from '../css/share-widget.module.css'

import {CopyToClipboard} from 'react-copy-to-clipboard';
import {
    FacebookIcon,
    FacebookShareButton,
    // FacebookMessengerShareButton,
    // FacebookMessengerIcon,
    TwitterShareButton,
    TwitterIcon,
    EmailShareButton,
    WhatsappShareButton,
    WhatsappIcon
} from 'react-share'
import CloseSVG from '../icons/white-close.svg'
import EmailSVG from '../icons/email.svg'
import LinkSVG from '../icons/link.svg'

const ShareWidget = ({ pageUrl, productTitle }) => {

    const { shareWidget, setShareWidget, notifyTextCopied } = useContext(StoreContext);

    const handleCloseShareWidget = () => {
        setShareWidget(false);
    }

    return(
        <>
        { shareWidget === true && <div className="mobile-shade" /> }
        <div className={`${styles.shareWidget} ${shareWidget === true ? styles.shareWidgetActive : ``} pt-2 pl-2 text-dkblue`} >
            <div className="flex justify-end relative">
                <button
                    className="mr-2 absolute"
                    style={{
                        top: "-56px",
                        width: '40px',
                        height: '40px'
                    }}
                    onClick={() => handleCloseShareWidget()}
                >
                    <img
                        alt="Close Icon"
                        src={CloseSVG}
                    />
                </button>
            </div>
            <p>Share this product with friends</p>
            <div className="grid grid-cols-2">
                <FacebookShareButton
                    className={`flex items-center mb-6`}
                    url={pageUrl}
                    quote="Check out these threads from Rare Chicago!"
                    hashtag="#RareChicago"
                >
                    <FacebookIcon  size={32} round={false} />
                    <span className="ml-2">Facebook</span>
                </FacebookShareButton>
                {/* <FacebookMessengerShareButton
                    appId={`149015030225438`}
                    className={`flex items-center mb-6`}
                    url={pageUrl}
                >
                    <FacebookMessengerIcon size={32} round={false} />
                    <span className="ml-2">Messenger</span>
                </FacebookMessengerShareButton> */}
                <TwitterShareButton
                    title={productTitle}
                    className={`flex items-center mb-6`}
                    url={pageUrl}
                    via="rarechicagotech"
                    hashtags={['RareChicago']}
                >
                    <TwitterIcon size={32} round={false} />
                    <span className="ml-2">Twitter</span>
                </TwitterShareButton>
                <WhatsappShareButton
                    url={pageUrl}
                    title={productTitle}
                    className="flex items-center mb-6"
                >
                    <WhatsappIcon size={32} round={false} />
                    <span className="ml-2">WhatsApp</span>
                </WhatsappShareButton>
                <EmailShareButton
                    className="flex items-center mb-6"
                    url={pageUrl}
                    subject="Check out these threads from Rare Chicago!"
                >
                    <img
                        alt="Email icon"
                        src={EmailSVG}
                        style={{
                            width: '32px'
                        }}
                    />
                    <span className="ml-2">Email</span>
                </EmailShareButton>
                <CopyToClipboard
                    text={pageUrl}
                    onCopy={() => notifyTextCopied()}
                >
                    <button
                        className="flex items-center mb-6"
                    >
                        <img
                            alt="Copy link icon"
                            src={LinkSVG}
                            style={{
                                width: '32px'
                            }}
                        />
                        <span className="ml-2">Copy Link</span>
                    </button>
                </CopyToClipboard>
            </div>
        </div>
        </>
    )
}

export default ShareWidget;