import React from 'react'

import {
    FacebookIcon,
    FacebookShareButton,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    TwitterShareButton,
    TwitterIcon
} from 'react-share'

const DesktopShareLinks = ({ pageUrl, productTitle }) => {

    return(
        <div className="hidden laptop:flex items-center">
            <FacebookShareButton
                className="mb-6 mr-2"
                url={pageUrl}
                quote="Check out these threads from Rare Chicago!"
                hashtag="#RareChicago"
            >
                <FacebookIcon 
                    size={32}
                    round={true}
                    bgStyle={{ fill: 'white' }}
                    iconFillColor="#252F3E"
                />
            </FacebookShareButton>
            <FacebookMessengerShareButton
                appId={`149015030225438`}
                className="mb-6 mr-2"
                url={pageUrl}
            >
                <FacebookMessengerIcon
                    size={32}
                    round={true}
                    bgStyle={{ fill: 'white' }}
                    iconFillColor="#252F3E"
                />
            </FacebookMessengerShareButton>
            <TwitterShareButton
                className="mb-6"
                title={productTitle}
                url={pageUrl}
                via="rarechicagotech"
                hashtags={['RareChicago']}
            >
                <TwitterIcon
                    size={32}
                    round={true}
                    bgStyle={{ fill: 'white' }}
                    iconFillColor="#252F3E"
                />
            </TwitterShareButton>
        </div>
    )
}

export default DesktopShareLinks;