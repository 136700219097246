import React, { useState, useContext, useCallback, useEffect } from 'react';
import find from 'lodash/find'
import isEqual from 'lodash/isEqual'
import StoreContext from '../context/StoreContext'

import LoadingSVG from '../icons/loading.svg'

const ProductForm = ({ product }) => {
    const { options, variants, variants: [initialVariant], priceRange: { minVariantPrice } } = product
    const [variant, setVariant] = useState({ ...initialVariant })
    const [size, setSize] = useState('')
    const [color, setColor] = useState('')
    const [quantity, setQuantity] = useState(1)

    const { addVariantToCart, store: { client, adding }, } = useContext(StoreContext)

    const productVariant = client.product.helpers.variantForOptions(product, variant) || variant
    
    const [available, setAvailable] = useState(productVariant.availableForSale)

    const checkAvailability = useCallback(
        productId => {
            client.product.fetch(productId).then(fetchedProduct => {
                // this checks the currently selected variant for availability
                const result = fetchedProduct.variants.filter(
                variant => variant.id === productVariant.shopifyId
                )
                if (result.length > 0) {
                setAvailable(result[0].available)
                }
            })
        }, [client.product, productVariant.shopifyId])
        // Removed variants from dependency array !!!!! 

    useEffect(() => {
        checkAvailability(product.shopifyId)
    }, [productVariant, checkAvailability, product.shopifyId])

    const handleColorChange = (optionIndex, { target }) => {
        const { value } = target
        if(value === '') {
            setColor(value);
            return
        }
        setColor(value);
        const currentOptions = [...variant.selectedOptions]
    
        currentOptions[optionIndex] = {
          ...currentOptions[optionIndex],
          value,
        }
    
        const selectedVariant = find(variants, ({ selectedOptions }) =>
          isEqual(currentOptions, selectedOptions)
        )
    
        setVariant({ ...selectedVariant })
    }

    const handleSizeChange = (optionIndex, { target }) => {
        const { value } = target
        if(value === '') {
            setSize(value);
            return
        }
        setSize(value);
        const currentOptions = [...variant.selectedOptions]
    
        currentOptions[optionIndex] = {
          ...currentOptions[optionIndex],
          value,
        }
    
        const selectedVariant = find(variants, ({ selectedOptions }) =>
          isEqual(currentOptions, selectedOptions)
        )
    
        setVariant({ ...selectedVariant })
    }

    const handleQuantityChange = ({ target }) => {
        setQuantity(target.value)
    }

    const handleAddToCart = (e) => {
        e.preventDefault();
        addVariantToCart(productVariant.shopifyId, quantity)
    }

    const checkDisabled = (name, value) => {
        const match = find(variants, {
            selectedOptions: [
                {
                    name: name,
                    value: value,
                },
            ], })
        if (match === undefined) return true
        if (match.availableForSale === true) return false
        return true
    }

    const sizeOptions = [];
    const colorOptions = [];

    for(let i=0; i<options.length; i++) {
        if (options[i].name === 'Size') {
            sizeOptions.push(options[i]);
            sizeOptions.push(i);
        }
        if (options[i].name === 'Color') {
            colorOptions.push(options[i]);
            colorOptions.push(i);
        }
    }

    const price = Intl.NumberFormat(undefined, {
        currency: minVariantPrice.currencyCode,
        minimumFractionDigits: 2,
        style: 'currency',
    }).format(variant.price)

    return(
        <form
            id="product-form"
            onSubmit={e => handleAddToCart(e)}
        >
            <div className="flex">
                <div className="w-1/2">
                    <h3>{price}</h3>
                </div>
                <div className="w-1/2 flex justify-around">
                    {
                        available === true ?
                        <p className="text-sm font-bold self-end">In Stock</p>
                        : <p className="font-bold self-end text-sm text-dkpink">Out of Stock</p>
                    }
                </div>    
            </div>
            
            <div className="grid grid-cols-2 items-center mb-12">
                {colorOptions.length > 0 && 
                    <React.Fragment>
                        <label 
                            htmlFor={colorOptions[0].name}
                            className="font-bold mb-6"
                        >
                            {colorOptions[0].name}
                        </label>
                        <select
                            className="p-1 bg-dkblue mb-6"
                            name={colorOptions[0].name}
                            required
                            onChange={event => handleColorChange(colorOptions[1], event)}
                            onBlur={event => handleColorChange(colorOptions[1], event)}
                            value={color}
                        >
                            <option
                                value=""
                                disabled
                                defaultValue
                            >
                                Select a color
                            </option>
                            {colorOptions[0].values.map(value => (
                            <option
                                value={value}
                                key={`${colorOptions[0].name}-${value}`}
                                disabled={checkDisabled(colorOptions[0].name, value)}
                            >
                                {value}
                            </option>
                            ))}
                        </select>
                    </React.Fragment>
                }
                {sizeOptions.length > 0 && 
                    <React.Fragment>
                        <label 
                            htmlFor={sizeOptions[0].name}
                            className="font-bold mb-6"
                        >
                            {sizeOptions[0].name}
                        </label>
                        <select
                            className="p-1 bg-dkblue mb-6"
                            name={sizeOptions[0].name}
                            required
                            onChange={event => handleSizeChange(sizeOptions[1], event)}
                            onBlur={event => handleSizeChange(sizeOptions[1], event)}
                            value={size}
                        >
                            <option
                                value=""
                                disabled
                                defaultValue
                            >
                                Select a size
                            </option>
                            {sizeOptions[0].values.map(value => (
                            <option
                                value={value}
                                key={`${sizeOptions[0].name}-${value}`}
                                disabled={checkDisabled(sizeOptions[0].name, value)}
                            >
                                {value}
                            </option>
                            ))}
                        </select>
                    </React.Fragment>
                }
                <label
                    htmlFor="quantity"
                    className="font-bold"
                >
                    Quantity
                </label>
                <input
                    className="bg-dkblue border-b border-white text-center"
                    type="number"
                    id="quantity"
                    name="quantity"
                    min="1"
                    step="1"
                    onChange={handleQuantityChange}
                    value={quantity}
                />
            </div>
            <button
                type="submit"
                form="product-form"
                className={`${available === true ? `bg-white` : `bg-dkpink opacity-30`} text-black font-bold mb-12 block mx-auto`}
                style={{ width: '212px', height: '36px' }}
                disabled={!available || adding}
            >
                { adding === true ? 
                    <img
                        className="mx-auto"
                        alt="Loading icon"
                        src={LoadingSVG}                   
                    /> :
                    `Add to Cart`
                }
            </button>
        </form>
    )
}

export default ProductForm;