import React, { useContext, useEffect } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import StoreContext from '../context/StoreContext'

function ProductPageSEO({ description, lang, meta, title, image: metaImage, pathname, schema }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            keywords
          }
        }
      }
    `
  )

  const { setCurrentPage, scrollLock } = useContext(StoreContext)

  useEffect(() => {
      setCurrentPage('Products');
  }, [setCurrentPage])

  const metaDescription = description || site.siteMetadata.description
  const imageUrl = metaImage ? metaImage : null
  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null

  return (
    <Helmet
      htmlAttributes={{
        lang,
        class: scrollLock === true ? `scroll-lock` : ``
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={
        canonical
          ? [
              {
                rel: "canonical",
                href: canonical,
              },
            ]
          : []
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: "keywords",
          content: site.siteMetadata.keywords.join(","),
        },
        {
          property: `og:title`,
          content: `Rare Threads`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:site`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: `Rare Threads`,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          metaImage
            ? [
                {
                  property: `og:image`,
                  content: imageUrl,
                },
                {
                  property: `og:image:width`,
                  content: 1200,
                },
                {
                  property: `og:image:height`,
                  content: 630,
                },
                {
                  name: `twitter:card`,
                  content: `summary_large_image`,
                },
                {
                  name: `twitter:image`,
                  content: imageUrl
                },
              ]
            : [
                {
                  name: `twitter:card`,
                  content: `summary`,
                },
              ]
        )
      .concat(meta)}
    >
      <link rel="preload"
          as="font"
          href="/fonts/FascinateInline-Regular.woff2"
          type="font/woff2"
          crossOrigin="anonymous"
      />
      <link rel="preload"
            as="font"
            href="/fonts/Jura-Light.woff2"
            type="font/woff2"
            crossOrigin="anonymous"
      />
        { schema && 
            <script type="application/ld+json">{JSON.stringify(schema)}</script>
        }
    </Helmet>
  )
}

ProductPageSEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

ProductPageSEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  pathname: PropTypes.string,
}

export default ProductPageSEO;
